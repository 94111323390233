import Vue from 'vue'
import money from 'v-money'
 
// register directive v-money and component <money>
Vue.use(money, {precision: 4})

Vue.filter('toCurrency', function (value) {
    if (typeof value !== "number") {
        return value;
    }
    var formatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 0
    });
    return formatter.format(value);
});