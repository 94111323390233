import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import i18n from '@/i18n'
import '@/sass/overrides.sass'
import VuePlyr from 'vue-plyr'
import 'vue-plyr/dist/vue-plyr.css'
import CKEditor from '@ckeditor/ckeditor5-vue2';

Vue.use( CKEditor );
Vue.use(VuePlyr, {
  plyr: {}
})

Vue.use(Vuetify)

const theme = {
  primary: '#740286',
  secondary: '#9C27b0',
  accent: '#cd23f7',
  info: '#00CAE3',
}
// const db = firebase.database()
export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
  // db:db,
  theme: {
    themes: {
      dark: theme,
      light: theme,
    },
  },
  defaultAssets:false,
})
