<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text class="py-3 white--text">
          {{text}}
          <v-progress-linear
            :value="value"
            color="white"
            class="my-3"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  export default {
    data () {
      return {
        
      }
    },
    props:{
        text:String,
        dialog:Boolean,
        value:Number
    }
  }
</script>