// =========================================================
// * Vuetify Material Dashboard - v2.1.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vuetify-material-dashboard
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/base'
import './plugins/chartist'
import './plugins/vee-validate'
import './plugins/axios'
import './plugins/money'
import axios from 'axios'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import './registerServiceWorker'
import firebase from 'firebase/app'
import 'firebase/analytics'
import 'firebase/auth'
import { db } from './db'
import vuefire from './plugins/vuefire'




// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// var admin = require("firebase-admin");
// var firebaseConfig = {
//   apiKey: "AIzaSyCB4U-K-LSehAYeeqNMlJA0CR364_ASCqQ",
//   authDomain: "confabular-3367d.firebaseapp.com",
//   databaseURL: "https://confabular-3367d-default-rtdb.firebaseio.com",
//   projectId: "confabular-3367d",
//   storageBucket: "confabular-3367d.appspot.com",
//   messagingSenderId: "988159377018",
//   appId: "1:988159377018:web:0513fc527b91fb547b45c3",
//   measurementId: "G-QGRLQ57ZB4",
//   // credential: admin.credential.cert(serviceAccount),
// };
// Initialize Firebase
// firebase.initializeApp(firebaseConfig);
// firebase.analytics();

Vue.config.productionTip = false
Vue.prototype.$http = axios

let app = ''
firebase.auth().onAuthStateChanged(()=>{
  if(!app){
    app = new Vue({
      router,
      store,
      vuetify,
      vuefire,
      i18n,
      render: h => h(App),
    }).$mount('#app')
  }
})

