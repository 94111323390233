// Conveniently import this file anywhere to use db

import firebase from 'firebase/app'
import 'firebase/database'
var firebaseConfig = {
  apiKey: "AIzaSyCB4U-K-LSehAYeeqNMlJA0CR364_ASCqQ",
  authDomain: "confabular-3367d.firebaseapp.com",
  databaseURL: "https://confabular-3367d-default-rtdb.firebaseio.com",
  projectId: "confabular-3367d",
  storageBucket: "confabular-3367d.appspot.com",
  messagingSenderId: "988159377018",
  appId: "1:988159377018:web:0513fc527b91fb547b45c3",
  measurementId: "G-QGRLQ57ZB4"
};
firebase.initializeApp(firebaseConfig);
firebase.analytics();
/*
var admin = require("firebase-admin");
var serviceAccount = require("./serviceAccountKey.json");

admin.initializeApp({
  credential: admin.credential.cert(serviceAccount),
  databaseURL: "https://confabular-3367d-default-rtdb.firebaseio.com"
});
*/
export const db = firebase.database();


// Export types that exists in Firestore - Uncomment if you need them in your app
//  const { Timestamp, GeoPoint } = firebase.firestore
//  export { Timestamp, GeoPoint }
